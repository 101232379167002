import React, { Component } from 'react';

class Blog extends Component {
  render() {
    return (
      <section id="blog" className="container my-5">
        <h1></h1>
        <p></p>
      </section>
    );
  }
}

export default Blog;
