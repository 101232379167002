import React, { Component } from 'react';
import './About.css'; // Import the CSS file for styles
import companyIntro from '../images/company-intro.jpg';
import companyVision from '../images/company-vision.jpg';


class About extends Component {
  render() {
    return (
      <section id="about" className="container my-5">
        <h1 className="about-title">About Us</h1>

        <div className="row align-items-center card-body">
          {/* Company Introduction Card */}
          <div className="col-md-6">
            <div className="card company-intro-card">
              <div>
                <h5 className="card-title"></h5>
                <p className="card-text">
                  MAS Engineering Solutions is the gateway to engineering excellence. With a reputation for precision and a dedication to innovation, we stand at the forefront of technological advancement. Let us be your trusted partner in realizing your engineering dreams and achieving your project goals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 center">
            <img
              src={companyIntro} // Replace with your image path
              alt="Company Introduction"
              className="company-image"
              style={{ borderRadius: '10px' }}
            />
          </div>
        </div>

        {/* Mission Section */}
        <div className="mission-section">
          <h2 className="mission-title">Our Mission</h2>
          <p className="mission-description">
            Our mission is to deliver cutting-edge engineering solutions that exceed client expectations. We are dedicated to pushing the boundaries of technology, sustainability, and safety while fostering a culture of continuous improvement and innovation.
          </p>
        </div>

        <div class="vision-section">
          <div class="vision-image-container">
            <img src={companyVision} alt="Vision Image" class="vision-image" />
            <div class="company-vision-card">
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text">Our vision is to pioneer transformative engineering
                  breakthroughs that positively impact society and the
                  environment. We aspire to create a world where ingenuity
                  knows no bounds and where our solutions enhance the quality
                  of life for all.</p>
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default About;
