import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import emailjs from 'emailjs-com'; // Import EmailJS

import mas01 from '../images/MAS-01.png'; 


class Contact extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm('service_6hq350y', 'template_mt6ur91', e.target, 'BsLyIUDf2qGDe-ptE')
      .then((result) => {
        alert('Thank you for contacting us! We will get back to you soon.');
        console.log(result.text);
      }, (error) => {
        alert('There was an error sending your message. Please try again later.');
        console.log(error.text);
      });
  };

  render() {
    return (
      <>
        {/* Contact Us Section */}
        <section id="contact" className="container my-5">
          <h1 className="text-center mb-4" style={{ color: 'OrangeRed', fontWeight: 'bold' }}>Contact Us</h1>
          <div className="d-flex justify-content-center">
            <form onSubmit={this.handleSubmit} className="w-50">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" name="name" placeholder="Your Name" required />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" name="email" placeholder="Your Email" required />
              </div>
              <div className="mb-3">
                <label htmlFor="contact" className="form-label">Contact</label>
                <input type="text" className="form-control" id="contact" name="contact" placeholder="Your Contact Number" required />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Message</label>
                <textarea className="form-control" id="message" name="message" rows="3" placeholder="Your Message" required></textarea>
              </div>
              {/* Centered and Smaller Submit Button */}
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </section>

        {/* Footer Section */}
        <footer className="footer bg-dark text-light py-5">
          <div className="container">
            <div className="row">
              {/* Logo & Address */}
              <div className="col-md-4">
                <img 
                  src={mas01} 
                  alt="MAS Engineering"  
                  style={{ width: '300px', marginTop: '-60px', marginBottom: '-40px' }} // Increased size slightly
                />
                <p>
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> 141-C Rehmanpura, Ibrahim Street, Lahore
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} /> +92 333 5550788
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> masengineeringsol@gmail.com
                </p>
              </div>

              {/* Social Media Links */}
              <div className="col-md-4">
                <h5>Follow Us</h5>
                <div className="d-flex gap-3">
                  <a href="https://facebook.com" className="text-light">
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                  </a>
                  <a href="https://instagram.com" className="text-light">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </a>
                  <a href="https://linkedin.com" className="text-light">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </a>
                </div>
              </div>

              {/* Google Map */}
              <div className="col-md-4">
                <h5 style={{ color: 'OrangeRed', fontWeight: 'bold' }}>Our Location</h5>
                <div className="map-container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.0645924587843!2d74.31732187511942!3d31.522385847043402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919048440bb4fbb%3A0xbf972a141ed08f3b!2sIbrahim%20St%2C%20Rehmanpura%20Colony%2C%20Lahore%2C%20Punjab%2054000%2C%20Pakistan!5e0!3m2!1sen!2s!4v1727518572065!5m2!1sen!2s"
                    width="400"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>

            {/* Horizontal line */}
            <hr style={{ borderColor: 'rgba(255, 255, 255, 0.2)', marginTop: '40px' }} />

            {/* Copyright Section */}
            <div className="text-center">
              <p style={{ margin: '0', fontSize: '16px' }}>
                MAS Engineering Solutions © {new Date().getFullYear()} | All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Contact;
