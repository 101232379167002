import { Component } from 'react';
import './Hero.css';
import masVideo from '../Videos/mas-video.mp4'; 



class Hero extends Component {
  render() {
    return (
      <section id="home" className="hero d-flex justify-content-center align-items-center">
        <video className="mas-video" autoPlay muted loop>
          <source src={masVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
    );
  }
}

export default Hero;
