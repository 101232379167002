// import React from 'react';
import './Photos.css'; // Import the CSS file for animations
import p1 from '../images/p1.jpg'; 
import p2 from '../images/p2.jpg'; 
import p3 from '../images/p3.jpg'; 
import p4 from '../images/p4.jpg'; 

const Photos = () => {
  return (
    <div className="container mt-5">
      <h1 className="gallery-title">Photo Gallery</h1>
      

      <div className="row">
        {/* First Row */}
        <div className="col-md-6 photo-card">
          <img
            src={p1}
            alt="Sample Photo 1"
            className="img-fluid photo"
          />
          <p className="description">AC Cables Termination</p>
        </div>

        <div className="col-md-6 photo-card">
          <img
            src={p2}
            alt="Sample Photo 2"
            className="img-fluid photo"
          />
          <p className="description">15kW System - Canadian Solar N-Type Bifacial 575W </p>
        </div>
      </div>

      <div className="row mt-4">
        {/* Second Row */}
        <div className="col-md-6 photo-card">
          <img
            src={p3}
            alt="Sample Photo 3"
            className="img-fluid photo"
          />
          <p className="description">10kW System - Canadian Solar N-Type Bifacial 575W </p>
        </div>

        <div className="col-md-6 photo-card">
          <img
            src={p4}
            alt="Sample Photo 4"
            className="img-fluid photo"
          />
          <p className="description">Inverex Nitrox 8kW Hybrid, IP-65</p>
        </div>
      </div>
    </div>
  );
};

export default Photos;
